import { css } from '@emotion/react';
import { Theme } from '@mui/material';

export default (theme: Theme) => css`
	.hero-container {
		background-color: #2962ff;
		.hero-typography {
			color: #fff;
		}
	}
`;
