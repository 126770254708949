import React, { useEffect } from 'react';
import styles from './styles';
import { useTheme } from '@mui/material';
import Layout from '@components/common/Layouts/Landing';
import { Box, Grid, Alert, Button } from '@mui/material';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { HelpArticle } from '@models/helpArticle';
import { useSelector } from 'react-redux';
import { UserState } from '@store/user/types';
import { HelpCategory } from '@models/helpCategory';
import HelpPanel from '@components/Landing/Help/common/HelpPanel';
import Header from '@components/Landing/Help/common/Header';

import CardThumbnail from '@components/Landing/Pricing/common/CardThumbnail';
import Breadcrumbs from '@components/common/Breadcrumbs';

const Category: React.FC<{ category: HelpCategory; articles: HelpArticle[] }> = ({
	category,
	articles,
}) => {
	const theme = useTheme();
	const user = useSelector(({ user }) => user);
	useEffect(() => {}, [category?.shortName]);

	const data = useStaticQuery(graphql`
		{
			allHelpCategory {
				edges {
					node {
						title
						shortName
					}
				}
			}
		}
	`);

	const {
		allHelpCategory: { edges: categories },
	} = data;
	return (
		<Layout>
			<div css={styles(theme)}>
				<Header
					type="category"
					title={category.title}
					description={category.description}
					category={category}
				/>
				<HelpPanel
					tabValue={category?.shortName}
					categories={categories?.map((category) => ({ ...category.node }))}
				>
					<Breadcrumbs
						breadcrumbs={[
							{ path: '/help', name: 'Support Centre' },
							{ name: category?.title },
						]}
					/>
					<Grid
						container
						spacing={2}
						justifyContent="center"
						style={{ maxWidth: '800px', margin: `${theme.spacing(2)} auto` }}
					>
						{articles.map((article, index) => {
							return (
								<Grid
									item
									lg={4}
									sm={6}
									xs={12}
									key={index}
									sx={{ padding: `0 ${theme.spacing(2)}` }}
								>
									<CardThumbnail article={article} />
								</Grid>
							);
						})}
					</Grid>
				</HelpPanel>
			</div>
		</Layout>
	);
};
export default Category;
