import React from 'react';
import { graphql } from 'gatsby';
import Category from '../../views/Help/Category';
const helpCategoryTemplate = ({ data }) => {
	const {
		helpCategory,
		allHelpArticle: { edges: articles },
	} = data;

	return (
		<Category
			category={helpCategory}
			articles={articles?.map((article) => ({ ...article?.node }))}
		/>
	);
};
export default helpCategoryTemplate;

export const query = graphql`
	query($id: String) {
		helpCategory(_id: { eq: $id }) {
			_id
			title
			shortName
			description
		}
		allHelpArticle(filter: { category: { _id: { eq: $id } } }) {
			edges {
				node {
					id
					title
					shortName
					html
					category {
						shortName
					}
				}
			}
		}
	}
`;
