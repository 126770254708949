import React, { useContext } from 'react';
import { Link as RouterLink, useLocation } from '@reach/router';
import { Box, Link, Typography } from '@mui/material';
import { Breadcrumbs as MuiBreadcrumbs } from '@mui/material';

import { useTheme } from '@mui/material';
import styles from './styles';
import { RoutesContext } from '@helpers/contexts';
import { Routes } from '@models/navigation';

const Breadcrumbs: React.FC<{
	name?: React.ReactNode | string | null;
	breadcrumbs?: { name: React.ReactNode; path?: string }[];
	simple?: boolean;
}> = ({ breadcrumbs: _breadcrumbs, simple }) => {
	const location = useLocation();
	const { routeStack, currentRouteItem } = useContext(RoutesContext);
	const theme = useTheme();

	if (!routeStack?.length || currentRouteItem?.breadcrumbs === false) {
		return null;
	}

	const isDashboard = /^\/dashboard/.test(location.pathname);
	const isAdmin = /^\/admin/.test(location.pathname);

	const breadcrumbs = _breadcrumbs || [
		...(isDashboard
			? [{ name: 'Dashboard', path: '/dashboard' }]
			: isAdmin
			? [{ name: 'Admin', path: '/admin' }]
			: []),
		...routeStack,
	];

	if (simple)
		return (
			<Box sx={{ mb: 3 }}>
				<MuiBreadcrumbs aria-label="breadcrumb">
					{breadcrumbs?.map((value, index) => {
						if (index === breadcrumbs?.length - 1 || !value?.path) {
							return (
								<Typography color="text.primary" component="p" key={index}>
									{value?.name}
								</Typography>
							);
						}

						return (
							<Link
								component={RouterLink}
								color="inherit"
								to={value?.path}
								key={index}
							>
								{value?.name}
							</Link>
						);
					})}
				</MuiBreadcrumbs>
			</Box>
		);

	return (
		<div css={styles(theme)}>
			<MuiBreadcrumbs
				separator={
					<Box
						component="span"
						sx={{
							width: 4,
							height: 4,
							borderRadius: '50%',
							bgcolor: 'text.disabled',
						}}
					/>
				}
				aria-label="breadcrumb"
			>
				{breadcrumbs?.map((item: any, index: number) => {
					return currentRouteItem?.path === item?.path ? (
						<Typography
							key={item?.name}
							variant="body2"
							sx={{
								maxWidth: 260,
								overflow: 'hidden',
								whiteSpace: 'nowrap',
								color: 'text.disabled',
								textOverflow: 'ellipsis',
							}}
						>
							{item?.name}
						</Typography>
					) : (
						<Link
							key={item?.name}
							variant="body2"
							component={RouterLink}
							to={item?.path || '#'}
							sx={{
								lineHeight: 2,
								display: 'flex',
								alignItems: 'center',
								color: 'text.primary',
								'& > div': { display: 'inherit' },
							}}
						>
							{item?.name}
						</Link>
					);
				})}
			</MuiBreadcrumbs>
		</div>
	);
};

export default Breadcrumbs;
