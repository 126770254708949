import { Theme } from '@mui/material';
import { css } from '@emotion/react';

export default (theme: Theme) => css`
	&.breadcrumbs {
		padding: ${theme.spacing(2.25, 2, 2.25, 0)};
		margin-bottom: -${theme.spacing(1)};

		li {
			margin-bottom: ${theme.spacing(1)};
		}

		.breadcrumb-item {
			a {
				transition: ${theme.transitions.create('color', {
					easing: theme.transitions.easing.sharp,
					duration: theme.transitions.duration.enteringScreen,
				})};
			}

			a,
			a:hover {
				text-decoration: none;
			}

			a:hover {
				color: ${theme.palette.text.primary};
			}
		}
	}
`;
